






import { Component, Vue } from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import XTabs from "@/components/SimpleTabs.vue";
import TurnoversTabContent from "@/components/for-turnovers-and-reports-view/TurnoversTabContent.vue";
import ReportsTabContent from "@/components/for-turnovers-and-reports-view/ReportsTabContent.vue";
import AnalysisTabContent from "@/components/for-turnovers-and-reports-view/AnalysisTabContent.vue";
import {mapState} from "vuex";

@Component({
  components: {
    Page,
    XTabs,
    TurnoversTabContent,
    ReportsTabContent,
    AnalysisTabContent
  },
  computed: {
    ...mapState({
      appEnvironment: "appEnvironment",
    }),
    titles() {
      if (this.appEnvironment.constants["BRANCH"] == "scs") {
        return this.titles2;
      }
      return this.titles1;
    },
    components() {
      if (this.appEnvironment.constants["BRANCH"] == "scs") {
        return this.components2;
      }
      return this.components1;
    }
  }
})
class TurnoversAndReports extends Vue {
  // отчеты отображаем только для самары
  titles1 = ["Обороты", "Анализ потребления"];
  components1 = [TurnoversTabContent, AnalysisTabContent];
  titles2 = ["Обороты", "Анализ потребления", "Отчеты"];
  components2 = [TurnoversTabContent, AnalysisTabContent, ReportsTabContent];

  mounted() {
  }
}

export default TurnoversAndReports;
